import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of, EMPTY } from 'rxjs';
import { catchError, retry, map, concatMap } from 'rxjs/operators';
import { DeviceUpdate } from '../types';
import { AssetData, OrgsData } from '../shared/models';
import { origin } from '../shared/utils/http-interceptor';
import { AbstractService } from './service';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json', // eslint-disable-line
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DataoutserviceService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  getLastStamps(): Observable<any> {
    return this.http.post<any>(`${this.baseApiUrl}/getlaststamp`, httpOptions);
  }

  getListOfOrgs(): Observable<any> {
    return this.http.post<OrgsData>(
      `${this.baseApiUrl}/getlistoforgs`,
      httpOptions
    );
  }

  getListOfAssetsInOrg(org: string): Observable<any> {
    const body = { org };
    return this.http.post<AssetData>(
      `${this.baseApiUrl}/getlistofassetsinorg`,
      body,
      httpOptions
    );
  }

  getListOfDevicesInOrg(org: string): Observable<any> {
    const body = { org };
    return this.http.post<any>(
      `${this.baseApiUrl}/getlistofdevicesinorg`,
      body,
      httpOptions
    );
  }

  getTiveHistory(): Observable<any> {
    return this.http.post<any>(
      `${this.baseApiUrl}/getalltivehistory`,
      httpOptions
    );
  }

  deleteAsset(assetId: string, org: string): Observable<any> {
    return this.http.delete<any>(`${this.baseApiUrl}/asset`, {
      ...httpOptions,
      params: { assetId, org },
    });
  }

  /* Unpair device first then delete asset */
  removeAsset(org: string, assetId: string, deviceId?: string) {
    if (!deviceId) return this.deleteAsset(assetId, org);
    return this.unpairDevice(deviceId, org).pipe(
      concatMap(() => this.deleteAsset(assetId, org))
    );
  }

  /* If deviceId is not provided, do not call the deeboop endpoint */
  unpairDevice(deviceId: string, org: string): Observable<any> {
    const body = { deviceId };
    return this.http.post<any>(`${this.baseApiUrl}/deboop`, body, {
      ...httpOptions,
      responseType: 'text' as 'json',
      params: { org },
    });
  }
}
